import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { formatDate, DateFormat } from "../utils/formatDate.ts";
import { Typography } from "@mui/material";
export const cardsColumns = [
  {
    field: "",
    headerName: "#",
    width: 50,
    sortable: false,
    renderCell: (index) =>
      index.api.state.pagination.page * index.api.state.pagination.pageSize +
      index.api.getRowIndex(index.row.serial_number) +
      1,
  },
  {
    field: "action",
    headerName: "Action",
    width: 180,
    sortable: false,
  },
  {
    field: "serial_number",
    headerName: "Serial number",
    width: 200,
  },
  {
    field: "name",
    headerName: "Card name",
    width: 200,
  },
  {
    field: "banGame",
    headerName: "Ban in game system",
    width: 150,
    sortable: false,
    renderCell: (params) => {
      return params.row.banGame ? <CheckCircleIcon color="success" fontSize="medium" /> : "";
    },
  },
  {
    field: "banUnrank",
    headerName: "Ban in Unrank mode",
    width: 150,
    sortable: false,
    renderCell: (params) => {
      return params.row.banUnrank ? <CheckCircleIcon color="success" fontSize="medium" /> : "";
    },
  },
  {
    field: "banRank",
    headerName: "Ban in Rank mode",
    width: 150,
    sortable: false,
    renderCell: (params) => {
      return params.row.banRank ? <CheckCircleIcon color="success" fontSize="medium" /> : "";
    },
  },
  {
    field: "banChallenge",
    headerName: "Ban in Challenge mode",
    width: 180,
    sortable: false,
    renderCell: (params) => {
      return params.row.banChallenge ? <CheckCircleIcon color="success" fontSize="medium" /> : "";
    },
  },
  {
    field: "banEvent",
    headerName: "Ban in event mode",
    width: 200,
    sortable: false,
    renderCell: (params) => {
      return (
        <Typography variant="p" style={{ overflowWrap: "break-word" }}>
          {params.row.banEvent && params.row.banEvent.eventIds.length === 0
            ? "All event"
            : params.row.eventNames}
        </Typography>
      );
    },
  },
  {
    field: "createdAt",
    headerName: "CreatedAt",
    renderCell: (params) => {
      return formatDate(params.row.createdAt, DateFormat.FULL_DATE_TIME);
    },
    width: 150,
  },
];
