// Argon Dashboard 2 MUI components
import { Icon } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonBadge from "components/ArgonBadge";
import ArgonButton from "components/ArgonButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { formatDate, DateFormat } from "../utils/formatDate.ts";
import DoDisturbOnRoundedIcon from "@mui/icons-material/DoDisturbOnRounded";
import { Link } from "react-router-dom";

function Author({ image, name }) {
  return (
    <ArgonBox display="flex" alignItems="center" px={1} py={0.5}>
      <ArgonBox mr={2}>
        <ArgonAvatar src={image} alt={name} size="sm" variant="rounded" />
      </ArgonBox>
      <ArgonBox display="flex" flexDirection="column">
        <ArgonTypography variant="button" fontWeight="medium">
          {name}
        </ArgonTypography>
      </ArgonBox>
    </ArgonBox>
  );
}

export const userColumns = [
  {
    field: "",
    headerName: "#",
    width: 50,
    sortable: false,
    renderCell: (index) =>
      index.api.state.pagination.page * index.api.state.pagination.pageSize +
      index.api.getRowIndex(index.row._id) +
      1,
  },
  {
    field: "action",
    headerName: "Action",
    width: 100,
    sortable: false,
    renderCell: (params) => {
      return (
        <ArgonBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
          <ArgonButton
            variant="outlined"
            color="dark"
            component={Link}
            to={`/users/${params.row._id}`}
          >
            View
          </ArgonButton>
        </ArgonBox>
      );
    },
  },
  {
    field: "username",
    headerName: "User",
    width: 120,
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    renderCell: (params) => {
      return (
        <ArgonBadge
          badgeContent={params.row.status}
          color={
            params.row.status === "online"
              ? "success"
              : params.row.status === "offline"
              ? "secondary"
              : "error"
          }
          size="xs"
          container
        />
      );
    },
  },
  {
    field: "subscribed",
    headerName: "Subscribed",
    width: 120,
    sortable: false,
    renderCell: (params) => {
      return params.row.subscribe ? (
        <CheckCircleIcon color="success" fontSize="medium" />
      ) : (
        <DoDisturbOnRoundedIcon color="error" fontSize="medium" />
      );
    },
  },
  {
    field: "connectedWallet",
    headerName: "Connected Wallet",
    width: 130,
    sortable: false,
    renderCell: (params) => {
      return params.row.publicAdress ? (
        <CheckCircleIcon color="success" fontSize="medium" />
      ) : (
        <DoDisturbOnRoundedIcon color="error" fontSize="medium" />
      );
    },
  },
  {
    field: "battle",
    headerName: "Battle",
    width: 70,
    renderCell: (params) => {
      return params.row.battle ? params.row.battle : 0;
    },
  },
  {
    field: "winRate",
    headerName: "Win rate",
    width: 80,
    sortable: false,
  },
  {
    field: "currentMmr",
    headerName: "Current MMR",
    width: 120,
  },
  {
    field: "currentRank",
    headerName: "Current Rank",
    width: 120,
  },
  {
    field: "worldRank",
    headerName: "World ranking",
    width: 120,
  },
  {
    field: "highestRank",
    headerName: "Highest rank achieved",
    width: 170,
  },
  {
    field: "createdAt",
    headerName: "CreatedAt",
    renderCell: (params) => {
      return formatDate(params.row.createdAt, DateFormat.FULL_DATE_TIME);
    },
    width: 150,
  },
  {
    field: "lastActivity",
    headerName: "Last activity",
    renderCell: (params) => {
      return params.row.lastActivity
        ? formatDate(params.row.lastActivity, DateFormat.FULL_DATE_TIME)
        : "";
    },
    width: 150,
  },
];

//temporary data
export const deckDetailColumns = [
  {
    field: "",
    headerName: "#",
    width: 50,
    renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
  },
  {
    field: "serial_number",
    headerName: "Card serial",
    width: 200,
  },
  {
    field: "name",
    headerName: "Card Name",
    width: 200,
  },
  {
    field: "cost",
    headerName: "Cost",
    width: 150,
  },
  {
    field: "detailed_cost",
    headerName: "Cost detail",
    width: 200,
  },
];
