import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import { Checkbox, FormControlLabel } from "@mui/material";
// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

import { useNavigate } from "react-router-dom";
import { useLogin } from "queries/login.queries";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
const axios = require("axios");
// Image
const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg";

function Illustration() {
  let navigate = useNavigate();

  const [username, setUserName] = useState("");
  const [usernameError, setUserNameError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [keepLogin, setKeepLogin] = useState(null);

  const [loginError, setLoginError] = useState("");

  const { mutate } = useMutation(useLogin, {
    onSuccess: (res) => {
      localStorage.setItem("accessToken", res.accessToken);
      if (keepLogin) {
        localStorage.setItem("refreshToken", res.refreshToken);
      }
      localStorage.setItem("email", res.email);
      axios.defaults.headers.common["Authorization"] = "Bearer " + res.accessToken;
      toast.success("Login Success!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        navigate("/");
      }, 2000);
    },
    onError: (error) => {
      toast.error("Login Fail!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  });

  const login = async () => {
    let check = false;
    if (!username.trim()) {
      check = true;
      setUserNameError("This field is required.");
    }

    if (!password) {
      check = true;
      setPasswordError("This field is required.");
    }

    if (!check) {
      mutate({
        emailOrUsername: username.trim().toString().toLowerCase(),
        password: password,
      });
    }
  };

  return (
    <IllustrationLayout
      title=""
      description=""
      illustration={{
        image: bgImage,
        title: '"Supa is new game"',
        description: "Supa is new game.",
      }}
    >
      <ArgonBox component="form" role="form">
        <ArgonBox mb={2}>
          <ArgonInput
            type="email"
            placeholder="Enter your Username or Email"
            size="large"
            onChange={(e) => setUserName(e.target.value)}
          />
          {usernameError && (
            <ArgonTypography variant="body2" fontWeight="regular" color="error">
              {usernameError}
            </ArgonTypography>
          )}
          {loginError && (
            <ArgonTypography variant="body2" fontWeight="regular" color="error">
              {loginError}
            </ArgonTypography>
          )}
        </ArgonBox>
        <ArgonBox mb={2}>
          <ArgonInput
            type="password"
            placeholder="Password"
            size="large"
            onChange={(e) => setPassword(e.target.value)}
          />
          {passwordError && (
            <ArgonTypography variant="body2" fontWeight="regular" color="error">
              {passwordError}
            </ArgonTypography>
          )}
        </ArgonBox>
        <FormControlLabel
          label="Keep me logged in"
          sx={{ paddingLeft: "10px", display: "flex" }}
          control={
            <Checkbox
              sx={{ ml: 1.2 }}
              onChange={(e) => {
                if (keepLogin) {
                  setKeepLogin(false);
                } else {
                  setKeepLogin(true);
                }
              }}
            />
          }
        />
        <ArgonBox mt={4} mb={1}>
          <ArgonButton color="info" size="large" fullWidth onClick={login}>
            Login
          </ArgonButton>
        </ArgonBox>
      </ArgonBox>
    </IllustrationLayout>
  );
}

export default Illustration;
