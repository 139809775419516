import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
const { fetch } = require("../service/index");

const useGetEvents = (param) => {
  return useQuery(
    [
      "all-events",
      {
        ...param,
      },
    ],
    async (query) => {
      const res = await fetch({
        url: `/api/event`,
        params: {
          ...query.queryKey[1],
        },
      });
      if (res.data && res.data.rows.length > 0) {
        res.data.rows.map((doc, index) => {
          doc.index = (Number(res.data.page) - 1) * Number(res.data.perPage) + index + 1;
        });
      }
      return res.data;
    }
  );
};

function useGetEventById(id) {
  return useQuery(
    ["events-detail"],
    async () => {
      const res = await fetch({
        url: `/api/event/` + id,
      });
      return res.data;
    },
    {
      retry: false,
    }
  );
}

function useAddEvent() {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) =>
      await fetch({
        url: `/api/event/create`,
        method: "POST",
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("all-events");
      },
    }
  );
}

function useUpdateEvent(id) {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) =>
      await fetch({
        url: `/api/event/${id}`,
        method: "PUT",
        data,
      }),
    {
      // when success auto call useGetNews with key: "all-news"
      onSuccess: () => {
        queryClient.invalidateQueries("all-events");
      },
    }
  );
}

function useStopEvent(id) {
  const queryClient = useQueryClient();
  return useMutation(
    async () =>
      await fetch({
        url: `/api/event/stop/${id}`,
        method: "GET",
      }),
    {
      // when success auto call useGetNews with key: "all-news"
      onSuccess: () => {
        queryClient.invalidateQueries("all-events");
      },
    }
  );
}

export { useGetEvents, useGetEventById, useAddEvent, useUpdateEvent, useStopEvent };
